import React, {useEffect, useState} from 'react';
import {Col, Row, Text, View} from '@unthinkable/react-core-components';
import Config from '../../../Config';
import {patientDetailStyles} from '../screens/style';
import moment from 'moment';
import Theme from '../../../theme/Theme';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType.js';
import {useNavigate} from 'react-router-dom';
import routeLink from '../../../constant/routeLink';
import {
  LeftNavBoxTitle,
  MainContainer,
  MiddleNavBoxTitle,
  NavBoxMessage,
  RightNavBoxTitle,
  StyledSeparator,
} from './PatientHeader.style';
import {Icon} from '../../../components/filePreviewer/Filepreviewer.style';
import images from '../../../assets/images';
import {HttpAuthService} from '../../../services';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {Confirm} from '@unthinkable/react-confirm';
import modalMessages from '../../../constant/ModalMessage';
import {useInvoke} from '../../../hooks';
import config from '../../../Config';
import {getZeroTimeDate} from '@unthinkable/react-filters';
import {TASK_TYPE} from '../../../constant/taskType';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {status} from '../../../constant/status';
import {useToast} from '@unthinkable/react-toast';
import {useEventHandler} from '@unthinkable/react-event-handler';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {FACILITY_TYPE} from '../../../constant/facilityType';
const getRemainingDaysMessage = task => {
  if (task?.status == STATUS.ACTIVE) {
    let concernedDate = task.tcmDueDate;
    if (
      new Date(task.tcmDueDate) < new Date() &&
      task.type !== TASK_TYPE.ContactTask
    ) {
      concernedDate = task.nextTcmDueDate;
    }
    const diff = moment(getZeroTimeDate(concernedDate)).diff(
      getZeroTimeDate(new Date()),
      'days',
    );
    if (diff < 0) {
      return 'Overdue by ' + Math.abs(diff) + ' days';
    } else if (diff == 0) {
      return 'Due Today';
    }
    return diff + ' days remaining';
  }
  return '';
};

const getVariant = (task, encounter) => {
  if (task?.status === STATUS.COMPLETE) {
    if (
      task.type === TASK_TYPE.ContactTask &&
      task?.successStatus &&
      encounter?.ContactTaskDuration <= config.contactTaskDuration
    )
      return 'success';
    else if (
      task.type === TASK_TYPE.VisitTask &&
      task?.successStatus &&
      encounter?.VisitTaskDuration <= config.visitTaskDuration
    )
      return 'success';
    else if (
      task.type === TASK_TYPE.MedicationTask &&
      task?.successStatus &&
      encounter?.MedicationTaskDuration <= config.medicationTaskDuration
    )
      return 'success';
    else if (task.type === TASK_TYPE.NotesTask && task?.successStatus)
      return 'success';
    else return 'alarm';
  } else {
    return 'warning';
  }
};

const LeftBox = ({title, message, variant = 'normal'}) => {
  return (
    <Row>
      <Col gap={4}>
        <LeftNavBoxTitle variant={variant}>
          <Icon
            source={
              variant == 'success' ? images.contactSuccess : images.contact
            }
          />
          {title}
        </LeftNavBoxTitle>
        <NavBoxMessage>{message}</NavBoxMessage>
      </Col>
      <StyledSeparator variant={variant} />
    </Row>
  );
};

const MiddleBox = ({title, message, variant = 'normal'}) => {
  return (
    <Row>
      <Col gap={4}>
        <MiddleNavBoxTitle variant={variant}>
          <Icon source={images.medRec} />
          {title}
        </MiddleNavBoxTitle>
        <NavBoxMessage>{message}</NavBoxMessage>
      </Col>
      <StyledSeparator variant={variant} />
    </Row>
  );
};

const RightBox = ({title, message, variant}) => {
  return (
    <Col gap={4}>
      <RightNavBoxTitle variant={variant}>
        <Icon source={images.visit} />
        {title}
      </RightNavBoxTitle>
      <NavBoxMessage>{message}</NavBoxMessage>
    </Col>
  );
};

export const TaskWidget = ({encounter, skipMessage = false}) => {
  const taskDetails = encounter?.taskInfo;
  const encounterDetail = encounter;

  const typeWiseTaskInfo = {};
  taskDetails?.forEach(task => (typeWiseTaskInfo[task.type] = task));
  if (
    encounterDetail &&
    (typeWiseTaskInfo?.ContactTask ||
      typeWiseTaskInfo?.MedicationTask ||
      typeWiseTaskInfo?.VisitTask ||
      typeWiseTaskInfo?.NotesTask)
  )
    return (
      <MainContainer>
        <RightBox
          title="Notes"
          message={
            !skipMessage && getRemainingDaysMessage(typeWiseTaskInfo?.NotesTask)
          }
          variant={getVariant(typeWiseTaskInfo?.NotesTask, encounterDetail)}
        />
        <MiddleBox
          title="Visit"
          message={
            !skipMessage && getRemainingDaysMessage(typeWiseTaskInfo?.VisitTask)
          }
          variant={getVariant(typeWiseTaskInfo?.VisitTask, encounterDetail)}
        />
        <MiddleBox
          title="Med Rec"
          message={
            !skipMessage &&
            getRemainingDaysMessage(typeWiseTaskInfo?.MedicationTask)
          }
          variant={getVariant(
            typeWiseTaskInfo?.MedicationTask,
            encounterDetail,
          )}
        />
        <LeftBox
          title="Contact"
          message={
            !skipMessage &&
            getRemainingDaysMessage(typeWiseTaskInfo?.ContactTask)
          }
          variant={getVariant(typeWiseTaskInfo?.ContactTask, encounterDetail)}
        />
      </MainContainer>
    );
};

export const PatientHeader = ({
  patient,
  encounter,
  visitTaskId,
  notesTaskId,
  patientDetail = false,
  medicationTask,
  isTaskAvailable = false,
  isTcmVisitTaskButtonVisible = false,
  isTcmMedicationButtonVisible,
  isGenerateNotesButtonVisible = false,
  isGenerateNotesButtonDisabled = true,
}) => {
  const {user} = useAuth();
  const {fonts} = Theme;
  const navigate = useNavigate();
  const toast = useToast();
  const {notify} = useEventHandler();
  const [loading, setLoading] = useState();
  const [isFetchClinicalSummaryVisible, setFetchClinicalSummaryVisible] =
    useState(false);
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['tcm-notes-status', 'addMedication'],
  });
  const getUpdatedPatient = async () => {
    const {data} = await HttpAuthService.get(`/v1/patients/${patient._id}`);
    const {status: patientStatus, outPatientClinicalSummary} = data;
    setFetchClinicalSummaryVisible(
      patientStatus === status.active &&
        outPatientClinicalSummary !== 'Success',
    );
  };
  useEffect(() => {
    if (patientDetail) getUpdatedPatient();
  }, []);
  const form1823Task =
    encounter?.taskInfo?.filter(task => task.type === TASK_TYPE.Form1823) || [];
  const isRequestForm1823Visible =
    !form1823Task?.length &&
    patient?.residence?.type === FACILITY_TYPE.AssistedLiving
      ? true
      : false;

  const fetchClinicalSummaryHandler = async () => {
    setLoading(true);
    const response = await HttpAuthService.get(
      `${routeLink.fetchClinicalSummary}`,
      {
        params: {patient: patient},
      },
    );
    if (response.type === 'Success') {
      notify &&
        notify('dataUpdated', {
          id: 'outpatientDiagnose',
        });
      notify &&
        notify('dataUpdated', {
          id: 'outpatient-medications',
        });
      setFetchClinicalSummaryVisible(false);
    }
    toast({
      message: response.message,
      type: response.type,
    });
    setLoading(false);
  };

  const requestForm1823Handler = async () => {
    setLoading(true);
    try {
      const response = await HttpAuthService.post(
        `${routeLink.CreateForm1823Task}`,
        {
          patientId: patient?._id,
          encounterId: encounter?._id,
        },
      );
      if (response?.success) {
        notify &&
          notify('form1823', {
            id: 'form1823Task',
          });
      }
      toast({
        message: response.message,
        type: response.success ? 'Success' : 'Error',
      });
    } catch (err) {
      toast({
        message: err.message,
        type: 'Error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Col gap={4}>
      <View style={patientDetailStyles.headerContainer}>
        <View style={patientDetailStyles.headerItemContainer}>
          <Text style={fonts.HEADING3}>
            {getPatientsFullName({
              FirstName: patient.FirstName,
              LastName: patient.LastName,
              MiddleName: patient.MiddleName,
              Render: false,
            })}
          </Text>
          {patient?.DOB && (
            <Text style={fonts.HEADING4}>
              {moment(patient?.DOB).utc().format(Config.dateFormat)}
            </Text>
          )}
          <Text style={fonts.HEADING4}>
            {patient?.Location ? ' | ' + patient.Location : ''}
          </Text>
        </View>

        <View style={patientDetailStyles.headerButtonContainer}>
          {patientDetail && (
            <View
              style={{
                ...patientDetailStyles.mr10,
                ...patientDetailStyles.styles.row,
                gap: 8,
              }}>
              {checkAuthorization(
                user,
                entity.fetchClinicalSummary,
                action.view,
              ) &&
                checkPrimaryPractice(user, patient) &&
                isFetchClinicalSummaryVisible && (
                  <Button
                    key="fetchClinicalSummary"
                    text="Fetch Clinical Summary"
                    onPress={fetchClinicalSummaryHandler}
                    loading={loading}
                    buttonType={buttonType.linkButtonWithBorder}
                  />
                )}
              {checkAuthorization(user, entity.patient, action.editView) &&
                checkPrimaryPractice(user, patient) && (
                  <Button
                    key="editPatient"
                    text="Edit Patient"
                    buttonType={buttonType.linkButtonWithBorder}
                    onPress={() => {
                      navigate(`${routeLink.editPatient}/${patient?._id}`, {
                        state: {
                          titleName: getPatientsFullName({
                            FirstName: patient.FirstName,
                            MiddleName: patient.MiddleName,
                            LastName: patient.LastName,
                            Render: false,
                          }),
                        },
                      });
                    }}
                  />
                )}
            </View>
          )}
          {!patientDetail &&
            isRequestForm1823Visible &&
            checkAuthorization(user, entity.form1823, action.create) && (
              <View
                style={{
                  ...patientDetailStyles.mr10,
                  ...patientDetailStyles.styles.row,
                  gap: 8,
                }}>
                <Button
                  key="Request Form 1823"
                  text="Request Form 1823"
                  onPress={requestForm1823Handler}
                  loading={loading}
                  buttonType={buttonType.linkButtonWithBorder}
                />
              </View>
            )}
          {isTcmMedicationButtonVisible && (
            <Confirm
              title={modalMessages.medicationReconciliationTaskApproval.title}
              message={
                modalMessages.medicationReconciliationTaskApproval.message
              }
              confirmText={
                modalMessages.medicationReconciliationTaskApproval.confirmText
              }
              onConfirm={async () => {
                await invoke({
                  uri: `/v1/tcmTasks/${medicationTask?._id}`,
                  data: {status: STATUS.COMPLETE},
                });
              }}>
              <Button
                key="completeMedicationTask"
                text="Complete Med Rec"
                icon={images.medRecComplete}
                iconPosition="right"
                buttonType={buttonType.linkButtonStyles}
              />
            </Confirm>
          )}
          {isTcmVisitTaskButtonVisible && (
            <View style={patientDetailStyles.mr10}>
              <Button
                key="completeVisitTask"
                text="Complete Visit"
                buttonType={buttonType.button}
                onPress={() => {
                  navigate(
                    `/patient/details/encounter/complete-visit/${visitTaskId}`,
                    {
                      state: {
                        titleName: patient.FullName,
                        patient: patient,
                        encounter: encounter,
                        taskId: visitTaskId,
                      },
                    },
                  );
                }}
              />
            </View>
          )}
          {isGenerateNotesButtonVisible && (
            <Row gap={4}>
              <Button
                key="Generate_TCM_Note"
                text="Generate TCM Note"
                buttonType={buttonType.linkButtonWithBorder}
                onPress={() => {
                  navigate(`/patient/details/encounter/tcm-notes`, {
                    state: {
                      titleName: patient.FullName,
                      patient: patient,
                      encounter: encounter,
                      taskId: notesTaskId,
                    },
                  });
                }}
                disabled={isGenerateNotesButtonDisabled}
              />
            </Row>
          )}
        </View>
      </View>
      {isTaskAvailable && (
        <TaskWidget patient={patient} encounter={encounter} />
      )}
    </Col>
  );
};
