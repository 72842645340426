import React, {useEffect, useState} from 'react';
import QRCode from 'react-qr-code';
import {useAuth} from '../hooks/useAuth';
import {ActivityIndicator} from 'react-native-web';
import HttpAuth from '../../../services/HttpAuthService';
import {TextRenderer} from '../../../components/formEditors/Editors';
import {AuthenticatorContainer} from './AuthenticatorSetup.style';

export const AuthenticatorSetup = ({userData}) => {
  const {user} = useAuth();
  const [secret, setSecret] = useState();
  const [loading, setLoading] = useState(true);
  const getSecret = async () => {
    const response = await HttpAuth.get('/v1/generateAuthenticatorSecret', {
      params: {userId: user?._id || userData?._id},
    });
    setSecret(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getSecret();
  }, [user?._id, userData?._id]);

  return loading ? (
    <ActivityIndicator />
  ) : (
    <AuthenticatorContainer>
      <TextRenderer value={`Scan this QR code with Authenticator App.`} />
      <QRCode value={secret.otpauthUrl} />
      <TextRenderer value={`Or use this setup key :: ${secret.secret}`} />
    </AuthenticatorContainer>
  );
};
