import React from 'react';
import {useParams} from 'react-router-dom';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {status} from '../../../constant/status';
import {USER_TYPE} from '../../../constant/userType';
import HttpAuth from '../../../services/HttpAuthService';
import {STATE_CODE, ZIPCODE_LEN} from '../../../constant/stateCode';
import {
  nameValidation,
  phoneAndFaxValidation,
  shortNameValidation,
} from '../../../utils/validations/commonValidations';

export const AddPractice = props => {
  const {onSubmit} = useFormSubmit({
    uri: routeLink.addPractice,
    eventSourceId: 'practices',
  });

  return (
    <Form
      saveButtonText={props.mode === 'edit' ? 'Save' : 'Create Practice'}
      onSubmit={onSubmit}
      computations={{
        getaddress: {
          compute: async value => {
            try {
              const {data} = await HttpAuth.get(routeLink.getAddressesFromZip, {
                params: {
                  zipCode: value.zipCode,
                },
              });
              return {
                city: data.city,
                state: STATE_CODE[data.state],
              };
            } catch (error) {
              return {
                city: '',
                state: '',
              };
            }
          },
          multi: true,
          dependencies: ['zipCode'],
          condition: ({zipCode}) => {
            return zipCode.length == ZIPCODE_LEN;
          },
        },
      }}
      layoutFields={[
        {
          label: props.mode === 'edit' ? 'Practice Info' : 'Add Practice',
          fields: [
            {
              type: 'text',
              label: 'Name',
              field: 'name',
              required: true,
              size: 'large',
              validate: nameValidation,
              readOnly: props.mode === 'edit',
            },
            {
              type: 'text',
              label: 'Short Name',
              field: 'shortName',
              required: true,
              validate: shortNameValidation,
              size: 'medium',
              readOnly: props.mode === 'edit',
            },
            {
              type: 'text',
              label: 'Street Address',
              field: 'streetAddress',
              required: true,
              size: 'large',
            },
            {
              type: 'text',
              label: 'Zip Code',
              field: 'zipCode',
              required: true,
              size: 'large',
            },
            {
              type: 'text',
              label: 'City',
              field: 'city',
              required: true,
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'State',
              field: 'state',
              required: true,
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'Phone No.',
              field: 'phoneNumber',
              required: true,
              validate: value => phoneAndFaxValidation(value, 'phone'),
              size: 'medium',
              readOnly: props.mode === 'edit',
            },
            {
              type: 'text',
              label: 'Fax No.',
              field: 'faxNumber',
              validate: value => phoneAndFaxValidation(value, 'fax'),
              size: 'medium',
              readOnly: props.mode === 'edit',
            },
            {
              type: 'file',
              label: 'Agreement',
              field: 'agreement',
              size: 'large',
              required: props.mode === 'edit',
              inputProps: {
                accept: '.pdf',
              },
            },
          ],
        },
        {
          label: 'Primary Admin',
          fields: [
            {
              type: 'autoComplete',
              field: 'primaryAdmin',
              label: 'Primary Admin',
              api: routeLink.getUser,
              sort: {name: 1},
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              size: 'medium',
              required: true,
              filter: JSON.stringify({
                status: status.active,
                practiceId: props?.api?.split('/').at(-1),
                'userType.name': USER_TYPE.PRACTICE_ADMIN,
              }),
            },
          ],
          visible: props.mode === 'edit',
        },

        {
          label: 'Facilities',
          fields: [
            {
              type: 'multiAutoComplete',
              field: 'facilities',
              label: 'Facilities',
              size: 'medium',
              required: false,
              valueField: 'name',
              sort: {name: 1},
              readOnly: true,
            },
          ],
          visible: props.mode === 'edit',
        },
        {
          label: 'Source',
          fields: [
            {
              type: 'text',
              label: 'Source ID',
              field: 'sourceId',
              readOnly: props.mode === 'edit',
              size: 'medium',
            },
            {
              type: 'text',
              label: 'Source Name',
              field: 'sourceName',
              readOnly: props.mode === 'edit',
              size: 'medium',
            },
          ],
        },
        {
          label: 'Destination',
          fields: [
            {
              type: 'text',
              label: 'Destination ID',
              field: 'destinationId',
              readOnly: props.mode === 'edit',
              size: 'medium',
            },
            {
              type: 'text',
              label: 'Destination Name',
              field: 'destinationName',
              readOnly: props.mode === 'edit',
              size: 'medium',
            },
          ],
        },
        {
          label: 'Facility Code',
          fields: [
            {
              type: 'text',
              label: 'Facility Code',
              field: 'facilityCode',
              readOnly: props.mode === 'edit',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditPractice = props => {
  const {practiceId} = useParams();
  return (
    <AddPractice
      {...props}
      header="Edit Practice"
      mode="edit"
      api={routeLink.practiceLink + `${practiceId}`}
      fields={{
        name: 1,
        shortName: 1,
        phoneNumber: 1,
        faxNumber: 1,
        streetAddress: 1,
        city: 1,
        state: 1,
        zipCode: 1,
        primaryAdmin: {
          name: 1,
        },
        facilities: {
          name: 1,
        },
        agreement: 1,
        sourceId: 1,
        sourceName: 1,
        destinationId: 1,
        destinationName: 1,
        facilityCode: 1,
      }}
    />
  );
};
