import React from 'react';
import {useNavigate} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {TextRender} from '@unthinkable/react-table';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';

import Config from '../../../Config';
import {transitionalEventStyle} from './style/transitionalEventTable.style';
import routeLink from '../../../constant/routeLink';
import locationType from '../../../constant/locationType.js';
import {Button} from '../../../components/button';
import buttonType from '../../../constant/buttonType';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {ENCOUNTER_STATUS} from '../../../constant/encounterStatus';

const renderLocation = ({row}) => {
  let location = '';
  if (row?.locationType?.value === locationType.Home) {
    location = row?.locationType?.value;
  } else {
    location = row?.location?.name;
  }
  return <TextRender value={location}></TextRender>;
};
export const TransitionalEventTable = props => {
  const {encounter, patient} = props;
  const {user} = useAuth();
  const navigate = useNavigate();
  return (
    <View style={transitionalEventStyle.containerView}>
      <TableHeader
        title={'Transitional Event'}
        actions={
          checkAuthorization(user, entity.transitionalEvent, action.create) &&
          checkPrimaryPractice(user, patient) &&
          encounter.status !== ENCOUNTER_STATUS.BILLABLE &&
          encounter.status !== ENCOUNTER_STATUS.INCOMPLETE
            ? [
                <Button
                  key="addTransitionalEvent"
                  buttonType={buttonType.linkButtonWithBorder}
                  text="Add Transitional Event"
                  onPress={() => {
                    navigate(
                      '/patient/details/encounter/add-transitional-event',
                      {
                        state: {
                          encounter: encounter,
                          patient: patient,
                        },
                      },
                    );
                  }}></Button>,
              ]
            : []
        }
      />
      <BasicTable
        api={routeLink.getTransitionalEvent}
        eventSourceId={'transitional-event'}
        fields={{
          event: 1,
          locationType: {
            value: 1,
          },
          location: {
            name: 1,
          },
          eventDate: 1,
        }}
        filter={{
          encounterId: encounter._id,
        }}
        sort={{eventDate: -1}}
        variant={'outline'}
        columns={[
          {
            header: 'Event',
            field: 'event',
            type: 'text',
          },
          {
            header: 'Location',
            render: renderLocation,
          },
          {
            header: 'Event Date & Time',
            field: 'eventDate',
            type: 'date',
            formatOptions: {
              format: Config.dateTimeFormat,
            },
            width: 200,
          },
        ]}
        {...props}
      />
    </View>
  );
};
