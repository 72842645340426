import React from 'react';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {useParams} from 'react-router-dom';

export const RejectReason = (props) => {
  const {newRequestId} = useParams();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.newRequest}/${newRequestId}`,
    eventSourceId: 'newRequest',
    method: 'put',

  });
  return (
    <Form
      saveButtonText={'Save'}
      onSubmit={onSubmit}
      defaultValues={{
        status: 'Rejected',
      }}
      layoutFields={[
        {
          label: 'Reject Reason',
          fields: [
            {
              type: 'text',
              label: 'Reason',
              field: 'rejectedReason',
              required: false,
              size: 'large',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
