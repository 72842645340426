import React, {useEffect, useState} from 'react';
import {Form} from '../../../components/form';
import {useFormSubmit} from '../../../hooks/useFormSubmit';
import {routeLink} from '../../../constant';
import {useAuth} from '../../auth/hooks/useAuth';
import {TableHeader} from '../../../components/header/TableHeader';
import {useLocation, useNavigate} from 'react-router-dom';
import {HttpAuthService} from '../../../services';
import {InputWrapper} from '@unthinkable/react-input';
import {StyledIframe} from './style/tcmNotes.style';
import {Button} from '../../../components/button';
import modalMessages from '../../../constant/ModalMessage';
import {Confirm} from '../../../components/confirm';
import {useInvoke} from '../../../hooks';

export const TcmNotesModal = props => {
  const {user} = useAuth();
  const {
    state: {encounter, titleName, taskId},
  } = useLocation();
  const navigate = useNavigate();  
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.getTCMEncounter}`,
    eventSourceId: ['tcm-document', 'tcmtasks'],
  });

  const postInvoke = useInvoke({
    method: 'post',
    eventSourceId: ['tcm-document', 'tcmtasks', 'tcm-notes-status'],
  });


  const RenderTextPreview = (props, {values}) => {
    const {tcmNotes, tcmTaskPerformedBy, updatePreview} = values;
    const [text, setText] = useState();
    const getTcmNotesPreview = async tcmNotes => {
      const {data: textData} = await HttpAuthService.get(routeLink.getTCMText, {
        params: {
          encounterId: encounter._id,
          notes: tcmNotes,
          tcmTaskPerformedBy: tcmTaskPerformedBy,
        },
      });
      setText(textData?.text);
    };
    useEffect(() => {
      getTcmNotesPreview(tcmNotes, tcmTaskPerformedBy);
    }, [updatePreview]);
    return (
      <InputWrapper {...props}>
        <StyledIframe srcDoc={text} />
      </InputWrapper>
    );
  };

  return (
    <>
      <TableHeader title="TCM Notes" secondaryTitle={titleName} />
      <Form
        saveButtonText={'Save For Later'}
        onSubmit={onSubmit}
        footer={{
          actions: props => {
            const {values} = props;
            return [
              <Confirm
                title={'Confirm'}
                secondaryTitle={'Tcm Notes'}
                message={modalMessages.tcmNotes.message}
                confirmText="Okay"
                key="Confirm"
                onConfirm={async () => {
                  postInvoke({
                    uri: routeLink.uploadTcmDocument,
                    data: {
                      updatedBy: user._id,
                      encounterId: encounter._id,
                      notes: values.tcmNotes,
                      tcmTaskPerformedBy: values.tcmTaskPerformedBy,
                      taskId: taskId,
                    },
                  });
                  navigate(-1);
                }}>
                <Button key={'Generate'} text={'Generate'} />
              </Confirm>,
            ];
          },
        }}
        mode="edit"
        api={routeLink.getTCMEncounter + '/' + encounter._id}
        layoutFields={[
          {
            label: 'TCM Notes',
            render: RenderTextPreview,
          },
          {
            fields: [
              {
                type: 'textArea',
                label: 'Add notes for the patient',
                field: 'tcmNotes',
                required: true,
                helperText:
                  '*Notes added here will appear in the TCM note under Plan of Care.',
                size: 10,
              },
              {
                type: 'textArea',
                label: 'User Name',
                field: 'tcmTaskPerformedBy',
                required: true,
                helperText:
                  '*Name added here will appear in the TCM note as a user who performed tcm tasks.',
                size: 10,
              },
              {
                render: (_, {values, setFieldValue}) => (
                  <Button
                    text="Update Preview"
                    onPress={() => {
                      setFieldValue('updatePreview', !values.updatePreview);
                    }}
                  />
                ),
                size: 2,
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
