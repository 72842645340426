import {View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';
import {Icon} from '../../../modules/facilities/screens/FacilityTable.style';
import React from 'react';
import images from '../../../assets/images';
import {hospitalizationMedicationStyle} from './style/HospitalizationMedicationTable';
import buttonType from '../../../constant/buttonType';
import {Button} from '../../../components/button/Button.js';
import {status} from '../../../constant/status';
import {combineText} from '../../../utils/commonMethod';
import {Confirm} from '../../../components/confirm';
import {useInvoke} from '../../../hooks';
import modalMessages from '../../../constant/ModalMessage';
import {TextRenderer} from '@unthinkable/react-text-input';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {useToast} from '@unthinkable/react-toast';
const RenderAction = ({row, patient, encounter}) => {
  const toast = useToast();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['addMedication'],
  });
  const {user} = useAuth();
  if (!row.medicationApproved && row.status === status.active) {
    return (
      <View style={hospitalizationMedicationStyle.actionContainer}>
        {encounter?.taskInfo?.length > 1 &&
          checkAuthorization(user, entity.medication, action.approve) && (
            <Confirm
              title={modalMessages.medicationTaskApproval.title}
              secondaryTitle={row.name}
              message={modalMessages.medicationTaskApproval.message}
              confirmText={modalMessages.medicationTaskApproval.confirmText}
              onConfirm={async () => {
                const result = await invoke({
                  uri: routeLink.updateOutPatientMedicationStatus,
                  data: {
                    _id: row._id,
                    patientId: patient?._id,
                    medicineId: row?.medicine,
                    Dose: row?.Dose,
                    Frequency: row?.Frequency,
                  },
                });
                toast(result);
              }}>
              <Icon src={images.checkCircle} />
            </Confirm>
          )}
        {encounter?.taskInfo?.length > 1 &&
          checkAuthorization(user, entity.medication, action.delete) && (
            <Confirm
              title={modalMessages.removeMedication.title}
              secondaryTitle={row.name}
              message={modalMessages.removeMedication.message}
              confirmText={modalMessages.removeMedication.confirmText}
              onConfirm={async () => {
                await invoke({
                  uri: `/v1/medications/${row._id}`,
                  data: {status: status.inactive},
                });
              }}>
              <Icon src={images.trash} />
            </Confirm>
          )}
      </View>
    );
  } else if (row.medicationApproved && row.status === status.active) {
    return (
      <View style={hospitalizationMedicationStyle.actionContainer}>
        <Icon src={images.contactSuccess} />
      </View>
    );
  }
};

export const HospitalizationMedication = props => {
  const {
    encounter,
    patient,
    isTcmMedicationButtonVisible,
    entity,
    medicationId,
  } = props;
  const navigate = useNavigate();
  const invoke = useInvoke({
    method: 'put',
    close: true,
    eventSourceId: ['tcmtasks'],
  });

  return (
    <View style={hospitalizationMedicationStyle.mainContainer}>
      <TableHeader
        title={'New Hospitalization Medication'}
        actions={[
          ...(isTcmMedicationButtonVisible
            ? [
                <Button
                  key="addMedication"
                  text="Add Medication"
                  icon={images.addIcon}
                  iconPosition="right"
                  buttonType={buttonType.linkButtonWithBorder}
                  onPress={() => {
                    navigate(
                      entity === 'TCMTASK'
                        ? `/tasks/medication-task/add-medication`
                        : `/patient/details/encounter/add-medication`,
                      {
                        state: {
                          encounter: encounter,
                          patient: patient,
                          titleName: patient.FullName,
                          entity: entity,
                        },
                      },
                    );
                  }}
                />,
              ]
            : []),
          ...(entity === 'TCMTASK'
            ? [
                <Confirm
                  key="editPatient"
                  title={
                    modalMessages.medicationReconciliationTaskApproval.title
                  }
                  message={
                    modalMessages.medicationReconciliationTaskApproval.message
                  }
                  confirmText={
                    modalMessages.medicationReconciliationTaskApproval
                      .confirmText
                  }
                  onConfirm={async () => {
                    await invoke({
                      uri: `/v1/tcmTasks/${medicationId}`,
                      data: {status: STATUS.COMPLETE},
                    });
                    navigate('/tasks');
                  }}>
                  <Button
                    key="editPatient"
                    text="Complete Med Rec"
                    icon={images.medRecComplete}
                    iconPosition="right"
                    buttonType={buttonType.linkButtonStyles}
                  />
                </Confirm>,
              ]
            : []),
        ]}
      />
      <BasicTable
        variant={'outline'}
        eventSourceId={'addMedication'}
        api={routeLink.getOutPatientMedication}
        fields={{
          medicine: {
            Name: 1,
          },
          Dose: 1,
          Frequency: 1,
          medicationApproved: 1,
          status: 1,
        }}
        filter={{
          encounterId: encounter._id,
        }}
        columns={[
          {
            header: 'Medication Name',
            field: 'medicine.Name',
            render: props => (
              <TextRenderer
                {...props}
                styles={{
                  ...props.styles,
                  text: {
                    ...props.styles.text,
                    textDecoration:
                      props?.row?.status == 'Inactive'
                        ? 'line-through'
                        : 'none',
                  },
                }}
              />
            ),
          },
          {
            header: 'Dose',
            render: ({row, ...rest}) => {
              return (
                <TextRenderer
                  {...rest}
                  value={combineText(row?.Dose?.Quantity, row?.Dose?.Units)}
                />
              );
            },
          },
          {
            header: 'Frequency',
            render: ({row, ...rest}) => {
              return (
                <TextRenderer
                  {...rest}
                  value={combineText(
                    row?.Frequency?.Period,
                    row?.Frequency?.Unit,
                  )}></TextRenderer>
              );
            },
          },
          {
            header: 'Reconcile',
            render: props => (
              <RenderAction
                {...props}
                patient={patient}
                encounter={encounter}
              />
            ),
          },
        ]}
        {...props}
      />
    </View>
  );
};
