import {View} from '@unthinkable/react-core-components';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import images from '../../../assets/images';
import {Button} from '../../../components/button';
import {Form} from '../../../components/form';
import {TableHeader} from '../../../components/header/TableHeader';
import {routeLink} from '../../../constant';
import buttonType from '../../../constant/buttonType';
import {useFormSubmit} from '../../../hooks';
import {addMedicationStyle} from '../screens/style/AddMedication.style';
import {Transitional_Event_Type} from '../constants/PatientConstants';
export const CreateNewTransitionalEvent = props => {
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.CreateNewTransitionalEvent}`,
    method: 'post',
    eventSourceId: ['transitional-event'],
  });
  const {
    state: {patient, encounter},
  } = useLocation();

  const RenderPlusButton = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    return (
      <View style={addMedicationStyle.buttonContainer}>
        <Button
          key="Add New Facility Form Transitional Event"
          icon={images.addIcon}
          buttonType={buttonType.linkButtonWithBorder}
          onPress={() => {
            navigate('/patient/details/encounter/add-new-facility', {
              state: {
                encounter: state?.encounter,
                patient: state?.patient,
              },
            });
          }}
        />
      </View>
    );
  };
  return (
    <>
      <TableHeader title="Create New Transitional Event" secondaryTitle={''} />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          patientId: patient._id,
          encounterId: encounter._id,
        }}
        computations={{
          patientClass: {
            compute: async values => {
              try {
                if(values?.event === Transitional_Event_Type.Admission)
                return "Inpatient";
              } catch (error) {
                return '';
              }
            },
            dependencies: ['event'],
          },
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'date',
                field: 'eventDate',
                label: 'Event Date',
                size: 'medium',
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'event',
                label: 'Event',
                required: true,
                options: ['Arrival', 'Admission', 'Discharge'],
                size: 'medium',
              },
              {
                type: 'autoComplete',
                field: 'dischargeType',
                label: 'Discharge Type',
                required: true,
                options: ['Home', 'Inpatient Facility'],
                size: 'medium',
                visible: ({values}) => {
                  return values.event === Transitional_Event_Type.Discharge;
                },
              },
              {
                type: 'autoComplete',
                field: 'patientClass',
                label: 'Classification',
                required: true,
                options: ['Emergency', 'Inpatient', 'OutPatient'],
                size: 'large',
              },
              {
                type: 'autoComplete',
                field: 'location',
                label: 'Location',
                api: routeLink.getFacilities,
                filter:{
                   isRedoxFacility:true,
                },
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                required: true,
              },
              {
                render: RenderPlusButton,
                size: 0.5,
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
