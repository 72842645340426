import {View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import {useNavigate} from 'react-router-dom';

import Config from '../../../Config';
import React from 'react';
import {patientDetailsWidgetStyle} from '../screens/style/PatientDetail.style';
import {TextRenderer} from '../../../components/formEditors/Editors';
import {Button} from '../../../components/button';
import buttonType from '../../../constant/buttonType';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {useAuth} from '../../../modules/auth/hooks/useAuth';

export const TCMEncounters = props => {
  const renderStatus = ({row}) => {
    return <TextRenderer value={row.status}></TextRenderer>;
  };
  const navigate = useNavigate();
  const {patient} = props;
  const {user} = useAuth();
  return (
    <View style={patientDetailsWidgetStyle}>
      <TableHeader
        title={'TCM Encounters'}
        actions={
          checkAuthorization(user, entity.encounter, action.create) &&
          checkPrimaryPractice(user, patient)
            ? [
                <Button
                  key="addFacility"
                  text="Create New Encounter"
                  onPress={() => {
                    navigate('/patient/details/create-new-encounter', {
                      state: {
                        patient: patient,
                        titleName: patient.FullName,
                      },
                    });
                  }}
                  // icon={addUser}
                  iconPosition="right"
                  buttonType={buttonType.linkButtonWithBorder}
                />,
              ]
            : []
        }
      />
      <BasicTable
        eventSourceId={'tcm-encounters'}
        onRowPress={({row}) => {
          navigate(`/patient/details/encounter`, {
            state: {
              encounter: row,
              patient: patient,
            },
          });
        }}
        variant={'outline'}
        columns={[
          {
            header: 'Admission Date',
            field: 'admissionDate',
            type: 'date',
            formatOptions: {
              format: Config.dateFormat,
            },
          },
          {
            header: 'Location',
            field: 'latestEventLocation.name',
            type: 'text',
          },
          {
            header: 'Discharge Date',
            field: 'dischargeDate',
            type: 'date',
            formatOptions: {
              format: Config.dateFormat,
            },
          },
          {
            header: 'Classification',
            field: 'patientClass',
            type: 'text',
          },
          {
            header: 'Status',
            render: renderStatus,
          },
        ]}
        {...props}
      />
    </View>
  );
};
