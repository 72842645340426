import checkAuthorization from '../../services/accessService';
import {Dashboard} from './screens/Dashborad';
import {action, entity} from '../../constant/authorization';

const stackRoutes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    visible: user => {
      if (user) {
        return checkAuthorization(user, entity.dashboard, action.view);
      }
      return true;
    },
  },
];

export default {
  stack: stackRoutes,
};
