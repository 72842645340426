import React from 'react';
import {useNavigate} from 'react-router-dom';
import {TableHeader} from '../../../components/header/TableHeader';
import {Table} from '../../../components/table';
import {useInvoke} from '../../../hooks';
import {
  AutoCompleteFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {routeLink} from '../../../constant';
import {useFilter} from '@unthinkable/react-filters';
import {TextRenderer} from '@unthinkable/react-text-input';
import Config from '../../../Config';
import {Icon} from '../../facilities/screens/FacilityTable.style';
import images from '../../../assets/images';
import {
  Patient_LIST_EVENT_SOURCE_ID,
  Patient_Location_Type,
} from '../constants/PatientConstants';
import {patientTableStyles} from './style';
import {Patient_Location_Status} from '../constants/PatientConstants';
import {Confirm} from '../../../components/confirm';
import {status} from '../../../constant/status';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import modalMessages from '../../../constant/ModalMessage';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {getPatientsFullName} from '../../../utils/commonMethod';
import moment from 'moment';

const RenderDelete = ({row}) => {
  const rowName = row?.patient?.FullName;
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: Patient_LIST_EVENT_SOURCE_ID,
  });

  if (row?.patient?.status === status.inactive) {
    return (
      <Confirm
        title={modalMessages.activate.title}
        secondaryTitle={rowName}
        message={`${modalMessages.activate.message} Patient`}
        confirmText="Confirm"
        onConfirm={async () => {
          await invoke({
            uri: `${routeLink.getPatient}/${row?.patient?._id}`,
            data: {status: status.active},
          });
        }}>
        <Icon src={images.activation} />
      </Confirm>
    );
  }

  return (
    <Confirm
      title={modalMessages.deactivate.title}
      secondaryTitle={rowName}
      message={`${modalMessages.deactivate.message} Patient`}
      confirmText="Confirm"
      onConfirm={async () => {
        await invoke({
          uri: `${routeLink.getPatient}/${row?.patient?._id}`,
          data: {status: status.inactive},
        });
      }}>
      <Icon src={images.trash} />
    </Confirm>
  );
};

const RenderChat = () => {
  return <Icon src={images.chatSmileSelected} />;
};

const locationStatus = ({row}) => {
  if (
    row?.patient?.currentLocationType?.value &&
    row?.patient?.residenceType?.value
  ) {
    const statusText =
      row?.patient?.currentLocation?.name == row?.patient?.residence?.name
        ? Patient_Location_Status.HOME
        : Patient_Location_Status.OUT;
    return (
      <div style={patientTableStyles.StatusContainer}>
        <div
          style={
            statusText == Patient_Location_Status.HOME
              ? patientTableStyles.GreenDot
              : patientTableStyles.RedDot
          }></div>
        <div style={patientTableStyles.textContainer}>{statusText}</div>
      </div>
    );
  }
  return '-';
};

export const PatientTableCareTeam = props => {
  const navigate = useNavigate();
  const filterProps = useFilter({
    params: {
      status: status.active,
    },
    filter: {},
  });
  const {searchValue, onChangeFilter, filterValues} = filterProps;
  const {params} = filterValues;

  if (params.status) {
    filterValues.filter = {
      ...filterValues.filter,
      'patient.status': params.status,
    };
  } else {
    delete filterValues?.filter?.['patient.status'];
  }

  const {user} = useAuth();

  return (
    <Table
      onRowPress={({row}) => {
        navigate(`/patient/details`, {
          state: {
            patient: row.patient,
            titleName: getPatientsFullName({
              FirstName: row.patient.FirstName,
              MiddleName: row.patient.MiddleName,
              LastName: row.patient.LastName,
              Render: false,
            }),
          },
        });
      }}
      renderHeader={() => (
        <TableHeader
          title={'Patient'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search patient'}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'$and'}
              options={[
                {
                  label: 'Home',
                  value: [
                    {'patient.residence': {$exists: true}},
                    {'patient.currentLocation': {$exists: true}},
                    {
                      $expr: {
                        $eq: ['$patient.residence', '$patient.currentLocation'],
                      },
                    },
                  ],
                },
                {
                  label: 'Out',
                  value: [
                    {'patient.residence': {$exists: true}},
                    {'patient.currentLocation': {$exists: true}},
                    {
                      $expr: {
                        $ne: ['$patient.residence', '$patient.currentLocation'],
                      },
                    },
                  ],
                },
                {
                  label: 'None',
                  value: [{'patient.residence': {$exists: false}}],
                },
              ]}
              key={'locationStatusFilter'}
              placeholder={'Location Status'}
              suggestionField={'label'}
              keyField={'value'}
              valueField={'label'}
              getDisplayValue={({options}) => {
                const selected = options?.find(
                  option =>
                    JSON.stringify(option.value) ===
                    JSON.stringify(filterValues?.filter?.['$and']),
                );
                return selected ? selected.label : '';
              }}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'patient.residence'}
              api={routeLink.getFacilities}
              filter={() => {
                return JSON.stringify({isRedoxFacility: false});
              }}
              key={'residenceFilter'}
              placeholder={'Residence'}
              suggestionField={'name'}
              sort={{name: 1}}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'status'}
              options={[status.active, status.inactive]}
              key={'statusFilter'}
              placeholder={'Status'}
              asParam={true}
            />,
          ]}
        />
      )}
      search={searchValue}
      sort={JSON.stringify({'patient.LastName': 1, 'patient.FirstName': 1})}
      searchFields={['patient.FullName']}
      api={routeLink.patientByCareTeam}
      eventSourceId={Patient_LIST_EVENT_SOURCE_ID}
      addOnFilter={JSON.stringify(filterValues.filter)}
      fields={{
        _id: 1,
        patient: {
          FirstName: 1,
          MiddleName: 1,
          LastName: 1,
          FullName: 1,
          DOB: 1,
          groupId: {
            _id: 1,
            name: 1,
            PNGroupId: 1,
          },
          residenceType: {
            value: 1,
          },
          currentLocationType: {
            value: 1,
          },
          residence: {
            name: 1,
            type: 1,
          },
          currentLocation: {
            name: 1,
          },
          primaryPractice: {
            name: 1,
          },
          latestEncounter: {
            dischargeDate: 1,
            taskInfo: {
              _id: 1,
              taskId: 1,
              status: 1,
              successStatus: 1,
              type: 1,
            },
          },
          outPatientClinicalSummary: 1,
          practices: 1,
          status: 1,
        },
      }}
      columns={[
        {
          header: 'Name',
          type: 'text',
          render: ({row}) => {
            const {FirstName, LastName, MiddleName} = row.patient;
            return getPatientsFullName({FirstName, LastName, MiddleName});
          },
        },
        {
          header: 'Date Of Birth',
          field: 'patient.DOB',
          type: 'date',
          formatOptions: {
            format: Config.dateFormat,
          },
        },
        {
          header: 'Location Status',
          render: locationStatus,
        },
        {
          header: 'Residence',
          render: ({row}) => {
            const text =
              row?.patient?.residenceType?.value == Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.patient?.residence?.name;
            return <TextRenderer value={text} />;
          },
        },
        {
          header: 'Current Location',
          render: ({row}) => {
            const text =
              row?.patient?.currentLocationType?.value ==
              Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.patient?.currentLocation?.name;
            return <TextRenderer value={text} />;
          },
        },
        {
          header: 'Status',
          field: 'patient.status',
          type: 'string',
        },
        {
          render: RenderChat,
          onPress: ({row}) => {
            navigate(
              `/patient/chat/${row?.patient?.groupId?.PNGroupId}/${row?.patient?.groupId?._id}`,
              {
                state: {
                  group: {
                    name:
                      getPatientsFullName({
                        FirstName: row.patient?.FirstName,
                        MiddleName: row.patient?.MiddleName,
                        LastName: row.patient?.LastName,
                        Render: false,
                      }) +
                      ' ' +
                      moment(row.patient.DOB).utc().format(Config.dateFormat),
                    _id: row.patient.groupId._id,
                  },
                  patientId: true,
                },
              },
            );
          },
          visible: user?.chatAuthKey,
          width: 50,
        },
        {
          render: RenderDelete,
          visible: () =>
            checkAuthorization(user, entity.patient, action.delete),
          width: 50,
        },
      ]}
      {...props}
    />
  );
};
