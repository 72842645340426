import React, {useState} from 'react';
import {View} from '@unthinkable/react-core-components';
import {useLocation} from 'react-router-dom';
import {
  PatientHeader,
  TransitionalEventTable,
  HospitalizationDiagnose,
  HospitalizationMedication,
} from '../components/index';
import {patientDetailStyles} from './style';
import {HttpAuthService} from '../../../services';
import {routeLink} from '../../../constant';
import {ContactAttempts} from '../components/ContactAttemptsTable';
import {status} from '../../../constant/status';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {TableHeader} from '../../../components/header/TableHeader';
import {TASK_TYPE} from '../../../constant/taskType';

export const PatientEncounterDetails = () => {
  const {
    state: {patient, encounter},
  } = useLocation();

  const {user} = useAuth();

  const [latestEncounter, setLatestEncounter] = useState(encounter);
  const taskInfo = latestEncounter?.taskInfo;
  const notesTaskId =
    taskInfo.find(task => task.type === TASK_TYPE.NotesTask)?._id || null;
  const notesTaskDetail =
    taskInfo.find(task => task.type === TASK_TYPE.NotesTask) || null;
  const visitTaskDetail =
    taskInfo.find(task => task.type === TASK_TYPE.VisitTask) || null;
  const medicationTaskDetail =
    taskInfo.find(task => task.type === TASK_TYPE.MedicationTask) || null;
  const contactTaskDetail =
    taskInfo.find(task => task.type === TASK_TYPE.ContactTask) || null;

  let isTcmMedicationButtonVisible = false;
  let isTcmVisitTaskButtonVisible = false;
  if (
    checkAuthorization(user, entity.TcmTasks, action.complete) &&
    user?.practiceId?._id === patient?.primaryPractice?._id &&
    medicationTaskDetail?.status &&
    medicationTaskDetail?.status !== status.complete
  ) {
    isTcmMedicationButtonVisible = true;
  }
  if (
    checkAuthorization(user, entity.TcmTasks, action.complete) &&
    user?.practiceId?._id === patient?.primaryPractice?._id &&
    visitTaskDetail?.status &&
    visitTaskDetail?.status !== status.complete
  ) {
    isTcmVisitTaskButtonVisible = true;
  }

  const getEncounter = async () => {
    const response = await HttpAuthService.get(routeLink.getTCMEncounter, {
      params: {
        filter: JSON.stringify({
          _id: latestEncounter?._id,
        }),
        fields: {
          admissionDate: 1,
          dischargeDate: 1,
          currentLocation: {
            name: 1,
          },
          status: 1,
          taskInfo: {
            _id: 1,
            taskId: 1,
            successStatus: 1,
            status: 1,
            type: 1,
          },
          tcmNotes: 1,
          diagnoseReason: 1,
          patientClass: 1,
          latestEventLocation: {
            name: 1,
          },
          ContactTaskDuration: 1,
          MedicationTaskDuration: 1,
          VisitTaskDuration: 1,
        },
        only: true,
      },
    });
    setLatestEncounter(response?.data);
  };

  useDataFetchEvents(
    {
      eventSourceId: [
        'transitional-event',
        'addMedication',
        'tcm-notes-status',
        'visitAttempt',
        'contactAttempts',
        'form1823Task',
      ],
    },
    () => {
      getEncounter();
    },
  );

  return (
    <View style={patientDetailStyles.styles.container}>
      <PatientHeader
        patient={patient}
        encounter={latestEncounter}
        notesTaskId={notesTaskId}
        visitTaskId={visitTaskDetail?.taskId}
        medicationTask={medicationTaskDetail}
        isTaskAvailable={latestEncounter?.taskInfo?.length ? true : false}
        isTcmVisitTaskButtonVisible={isTcmVisitTaskButtonVisible}
        isTcmMedicationButtonVisible={isTcmMedicationButtonVisible}
        isGenerateNotesButtonVisible={
          checkAuthorization(user, entity.TcmTasks, action.complete) &&
          checkPrimaryPractice(user, patient) &&
          notesTaskDetail?.status &&
          notesTaskDetail?.status !== status.complete
        }
        isGenerateNotesButtonDisabled={
          isTcmMedicationButtonVisible ||
          isTcmVisitTaskButtonVisible ||
          contactTaskDetail?.status === status.active
        }
      />
      {latestEncounter.diagnoseReason && (
        <View style={patientDetailStyles.styles.row}>
          <TableHeader title={'Hospitalization Reason:'} />
          {latestEncounter.diagnoseReason}
        </View>
      )}
      <View style={patientDetailStyles.styles.row}>
        <TransitionalEventTable patient={patient} encounter={latestEncounter} />
        {contactTaskDetail && (
          <ContactAttempts
            patient={patient}
            encounter={latestEncounter}
            taskId={contactTaskDetail.taskId}></ContactAttempts>
        )}
      </View>
      <View style={patientDetailStyles.styles.row}>
        <HospitalizationDiagnose encounter={latestEncounter} />
        <HospitalizationMedication
          encounter={latestEncounter}
          patient={patient}
          isTcmMedicationButtonVisible={isTcmMedicationButtonVisible}
          entity={'ENCOUNTER'}
          medicationId={null}
        />
      </View>
    </View>
  );
};
