export const TASK_TYPE_TO_SHOW = {
  ContactTask: 'Contact Task',
  MedicationTask: 'Medication Task',
  VisitTask: 'Visit Task',
  NotesTask: 'Notes Task',
  Form1823: 'Form 1823',
  'Initial Tcm Eligibility': 'Initial Tcm Eligibility',
  'Facility Assignment': 'Facility Assignment',
};
export const TASK_TYPE = {
  ContactTask: 'ContactTask',
  MedicationTask: 'MedicationTask',
  VisitTask: 'VisitTask',
  NotesTask: 'NotesTask',
  Form1823: 'Form1823',
  INITIAL_TCM_ELIGIBILITY: 'Initial Tcm Eligibility',
  FACILITY_ASSIGNMENT: 'Facility Assignment',
};

export const Tcm_TASKS = {
  ContactTask: 1,
  MedicationTask: 1,
  VisitTask: 1,
  NotesTask: 1,
};
export const TASK_STATUS = {
  Complete: 'Complete',
  Approved: 'Approved',
  Reject: 'Reject',
};

export const TASK_TABLE_AUTO_COMPLETE_OPTIONS = [
  {label: 'Medication Task', value: 'MedicationTask'},
  {label: 'Contact Task', value: 'ContactTask'},
  {label: 'Visit Task', value: 'VisitTask'},
  {label: 'Tcm Notes', value: 'NotesTask'},
  {label: 'Form1823', value: 'Form1823'},
];
