import React from 'react';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {useLocation} from 'react-router-dom';

export const FacilityAssignmentModal = props => {
  const {
    state: {tcmTaskId, patientId},
  } = useLocation();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.getTCMTask}/${tcmTaskId}`,
    method: 'put',
    eventSourceId: ['tcmtasks','facilityAssignment'],
  });
  return (
    <Form
      saveButtonText={'Save'}
      onSubmit={onSubmit}
      beforeSubmit={data => {
        return {
          data: {
            residence: data?.data?.location,
            currentLocation: data?.data?.location,
            status: 'Complete',
            successStatus: true,
          },
        };
      }}
      layoutFields={[
        {
          label: `Facility Assignment-${patientId?.FullName}`,
          fields: [
            {
              type: 'autoComplete',
              label: 'Current Location',
              api: routeLink.getFacilities,
              field: 'location',
              sort: {name: 1},
              suggestionField: 'name',
              required: true,
              size: 'large',
              filter: {
                isRedoxFacility: false,
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
