import {useAuth} from '../modules/auth/hooks/useAuth';

const {USER_TYPE} = require('../constant/userType');
const isAdmin = () => {
  const {user} = useAuth();
  if (user.userType.name === USER_TYPE.ADMIN) {
    return true;
  }
  return false;
};
const isFacilityAdmin = () => {
  const {user} = useAuth();
  if (user.userType.name === USER_TYPE.FACILITY_ADMIN) {
    return true;
  }
  return false;
};
const isPracticeAdmin = () => {
  const {user} = useAuth();
  if (user.userType.name === USER_TYPE.PRACTICE_ADMIN) {
    return true;
  }
  return false;
};
const isPracticeUser = () => {
  const {user} = useAuth();
  if (user.userType.name === USER_TYPE.PRACTICE_USER) {
    return true;
  }
  return false;
};
const isFacilityUser = () => {
  const {user} = useAuth();
  if (user.userType.name === USER_TYPE.FACILITY_USER) {
    return true;
  }
  return false;
};

const isMedicalProvider = () => {
  const {user} = useAuth();
  if (user.userType.name === USER_TYPE.MEDICAL_PROVIDER) {
    return true;
  }
  return false;
};

const isOfficeUser = () => {
  const {user} = useAuth();
  if (user?.userType?.name === USER_TYPE.OFFICE_USER) {
    return true;
  }
  return false;
};
export {
  isAdmin,
  isFacilityAdmin,
  isPracticeAdmin,
  isPracticeUser,
  isFacilityUser,
  isMedicalProvider,
  isOfficeUser,
};
