import {Image, View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../components/button';
import {TableHeader} from '../../../components/header/TableHeader';
import Config from '../../../Config';
import {Table} from '../../../components/table';
import images from '../../../assets/images';
import {
  AutoCompleteFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {useInvoke} from '../../../hooks';
import {Confirm} from '@unthinkable/react-confirm';

import {Icon} from '../../facilities/screens/FacilityTable.style';
import {
  ColumnTextRender,
  ColumnTextLink,
  GroupRowText,
} from '../../user/styles/UserTable.style';
import {
  USER_TYPE,
  STATUS,
  USERS_API_ENDPOINT,
  USERS_LIST_EVENT_SOURCE_ID,
} from '../constants/UserConstants';
import {useAuth} from '../../auth/hooks/useAuth';
import HttpAuth from '../../../services/HttpAuthService';
import {toasterMessage, routeLink} from '../../../constant';
import {useToast} from '@unthinkable/react-toast';
import {status} from '../../../constant/status';
import buttonType from '../../../constant/buttonType';
import {TextRenderer} from '../../../components/formEditors/Editors';
import {useFilter} from '@unthinkable/react-filters';
import {action, entity} from '../../../constant/authorization';
import modalMessages from '../../../constant/ModalMessage';
import {isAdmin} from '../../../utils/checkUserType';
import checkAuthorization from '../../../services/accessService';

const {defaultListLimit} = Config;

const RenderEnableDisable = ({row}) => {
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: USERS_LIST_EVENT_SOURCE_ID,
  });
  if (
    !row.isFacilityPrimaryAdmin &&
    !row.isPracticePrimaryAdmin &&
    row.userType.name !== USER_TYPE.ADMIN &&
    row.status !== STATUS.INVITED
  ) {
    if (row.status === STATUS.INACTIVE) {
      return (
        <Confirm
          title={modalMessages.activate.title}
          secondaryTitle={row.name}
          message={`${modalMessages.activate.message} User?`}
          confirmText="Confirm"
          onConfirm={async () => {
            await invoke({
              uri: '/v1/users/' + row._id,
              data: {status: STATUS.ACTIVE},
            });
          }}>
          <Icon src={images.activation} />
        </Confirm>
      );
    }
    return (
      <Confirm
        title={modalMessages.deactivate.title}
        secondaryTitle={row.name}
        message={`${modalMessages.deactivate.message} User?`}
        confirmText="Confirm"
        onConfirm={async () => {
          await invoke({
            uri: '/v1/users/' + row._id,
            data: {status: STATUS.INACTIVE},
          });
        }}>
        <Icon src={images.trash} />
      </Confirm>
    );
  }
};

const RenderUser = ({row}) => {
  return (
    <View>
      <ColumnTextRender title={row.name}> {row.name} </ColumnTextRender>
      <GroupRowText title={row.email}> {row.email} </GroupRowText>
    </View>
  );
};

const RenderUserStatus = ({row}) => {
  const toast = useToast();
  if (row.status === status.invited) {
    return (
      <View>
        <ColumnTextRender> {row.status} </ColumnTextRender>
        <ColumnTextLink
          onClick={async () => {
            try {
              await HttpAuth.post(routeLink.resendInviteLink, {
                email: row.email,
              });
              toast({
                message: toasterMessage.resendInvite.success,
                type: 'Success',
              });
            } catch (err) {
              toast({
                message: toasterMessage.resendInvite.failure,
                type: 'Error',
              });
            }
          }}>
          Resend
        </ColumnTextLink>
      </View>
    );
  }

  return (
    <View>
      <ColumnTextRender> {row.status} </ColumnTextRender>
    </View>
  );
};

const getColumns = ({edit, navigate, user}) => [
  {
    header: 'Name',
    render: RenderUser,
  },
  {
    header: 'User Type',
    field: 'userType.name',
  },
  {
    header: 'Phone Number',
    field: 'phoneNumber',
  },
  {
    header: 'Employer',
    render: ({row, ...rest}) => {
      return (
        <TextRenderer
          {...rest}
          title={
            row?.employerType?.name === entity.facilities
              ? row?.facilityId?.name
              : row?.practiceId?.name
          }
          value={
            row?.employerType?.name === entity.facilities
              ? row?.facilityId?.name
              : row?.practiceId?.name
          }
        />
      );
    },
  },
  {
    header: 'Status',
    render: RenderUserStatus,
  },
  {
    render: () => {
      return (
        <Image source={edit} style={{height: 24, width: 24, padding: 4}} />
      );
    },
    onPress: ({row}) => {
      navigate('/users/edit/' + row?._id, {
        state: {
          user: row,
          titleName: row.name,
        },
      });
    },
    visible: checkAuthorization(user, entity.user, action.edit),
    width: 50,
  },
  {
    render: RenderEnableDisable,
    width: 50,
    visible: checkAuthorization(user, entity.user, action.delete),
  },
];

const UserTable = props => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const {edit} = useTheme('icons');
  const {addUser} = useTheme('icons');

  const columns = useMemo(() => {
    return getColumns({edit, navigate, user});
  }, [edit]);

  const {searchValue, filterValues, onChangeFilter} = useFilter();

  filterValues.filter = {
    ...filterValues.filter,
    _id: {$ne: user._id},
  };

  if (!isAdmin()) {
    filterValues.filter = {
      ...filterValues.filter,
      employerType: user?.employerType?._id,
      practiceId: user?.practiceId?._id,
      facilityId: user?.facilityId?._id,
    };
  }

  return (
    <Table
      noDataText={'No users found'}
      {...props}
      renderHeader={() => (
        <TableHeader
          title={'Users'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search User'}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'status'}
              options={[STATUS.ACTIVE, STATUS.INACTIVE, STATUS.INVITED]}
              key={'statusFilter'}
              placeholder={'Status'}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'userType'}
              api={'/v1/userTypes'}
              key={'userTypeFilter'}
              suggestionField={'name'}
              placeholder={'User Type'}
              sort={{name: 1}}
            />,
            ...(checkAuthorization(user, entity.user, action.create)
              ? [
                  <Button
                    key="Invite User"
                    text="Invite User"
                    onPress={() => {
                      navigate('/users/add');
                    }}
                    icon={addUser}
                    iconPosition="right"
                    buttonType={buttonType.linkButtonWithBorder}
                  />,
                ]
              : []),
          ]}
        />
      )}
      api={USERS_API_ENDPOINT}
      addOnFilter={JSON.stringify(filterValues.filter)}
      eventSourceId={[USERS_LIST_EVENT_SOURCE_ID]}
      limit={defaultListLimit}
      sort={{name: 1}}
      search={searchValue}
      searchFields={['name', 'phoneNumber', 'email']}
      fields={{
        name: 1,
        email: 1,
        userType: {
          name: 1,
        },
        phoneNumber: 1,
        status: 1,
        isFacilityPrimaryAdmin: {
          _id: 1,
        },
        isPracticePrimaryAdmin: {
          _id: 1,
        },
        practiceId: {
          name: 1,
        },
        facilityId: {
          name: 1,
        },
        employerType: {
          name: 1,
        },
      }}
      columns={columns}
    />
  );
};

export default UserTable;
