import React, {useContext} from 'react';
import {cilHome} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {WithModal} from '@unthinkable/react-popper';
import {useEffect, useState} from 'react';
import {io} from 'socket.io-client';
import Config from '../Config';
import {AlertPopupTable} from '../modules/alerts/screens/AlertPopupTable';
import {useAuth} from '../modules/auth/hooks/useAuth';
import HttpAuth from '../services/HttpAuthService';
import {BreadCrumbContainer} from './AppBreadcrumb.style';
import {HeaderContainer, appHeaderStyles} from './AppHeader.style';
import AppHeaderAction from './AppHeaderAction';
import {AppBreadcrumb} from './index';
import {useInvoke} from '../hooks';
import {useStyles} from '@unthinkable/react-theme';
import {setGlobalClearAuth} from '../Navigation';
import {AuthContext} from '../context/auth/AuthContext';

const AppHeader = () => {
  const [notifications, setNotifications] = useState([]);
  const {user} = useAuth();
  const {clearAuth} = useContext(AuthContext);
  const loadNotifications = async () => {
    const {data} = await HttpAuth.get(`/v1/OneLinerAlerts`, {
      params: {
        filter: JSON.stringify({
          watcher: {$elemMatch: {userId: user._id, status: {$ne: 'read'}}},
        }),
        count: true,
      },
    });
    setNotifications(data);
  };
  useEffect(() => {
    setGlobalClearAuth(clearAuth);
  }, []);
  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    const socket = io(Config.socketUrl);
    socket.on('connect', () => {
      socket.on(`notification-${user._id}`, () => {
        loadNotifications();
      });
    });
    return () => {
      socket.off(`notification-${user._id}`); // Remove specific event listener
      socket.disconnect(); // Disconnect socket
    };
  }, [user._id]);

  const invoke = useInvoke({close: false, method: 'put'});

  const styles = useStyles(appHeaderStyles);

  return (
    <HeaderContainer>
      <BreadCrumbContainer>
        <CIcon icon={cilHome} size="lg" />
        <AppBreadcrumb />
      </BreadCrumbContainer>
      <WithModal
        position="right"
        hideModal={() => {
          invoke({uri: '/v1/mark-notification-read'});
        }}
        renderModal={AlertPopupTable}
        styles={styles}>
        <AppHeaderAction notifications={notifications.count} />
      </WithModal>
    </HeaderContainer>
  );
};

export default AppHeader;
