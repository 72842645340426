import theme from '../../../theme/Theme';
import styled from 'styled-components';
const ChatHeaderMainDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${theme.colors.NEUTRAL_HIGH};
  color: ${theme.colors.BACKGROUND};
  font-weight: 700;
  padding: 12px 16px;
`;
const MemberIconStyle = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export {ChatHeaderMainDiv, MemberIconStyle};
