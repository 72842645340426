import {View} from '@unthinkable/react-core-components';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import images from '../../../assets/images';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType.js';
import Config from '../../../Config';
import {TCMContactStatus, status} from '../../../constant/status';
import {Icon} from './style/contactAttempts.style';
import {HttpAuthService} from '../../../services';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {routeLink} from '../../../constant';
import {useAuth} from '../../../modules/auth/hooks/useAuth';

import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {TextRenderer} from '@unthinkable/react-text-input';

const RenderAttemptCount = ({index, ...rest}) => {
  return <TextRenderer {...rest} value={`${index + 1} of 3`} />;
};

const RenderStatus = ({row}) => {
  if (row.contactStatus === TCMContactStatus.successful) {
    return (
      <Icon src={images.checkCircle} title={TCMContactStatus.successful} />
    );
  } else if (row.contactStatus === TCMContactStatus.unsuccessful) {
    return (
      <Icon src={images.closeCircle} title={TCMContactStatus.unsuccessful} />
    );
  } else {
    return null;
  }
};

export const ContactAttempts = props => {
  const {patient, encounter, taskId} = props;
  const navigate = useNavigate();
  const [contactAttemptList, setContactAttemptList] = useState([]);
  const [taskDetail, setTaskDetail] = useState(null);

  const {user} = useAuth();
  let isShowAddButton = false;
  if (
    taskDetail &&
    taskDetail.status !== status.complete &&
    checkAuthorization(user, entity.TcmTasks, action.complete) &&
    checkPrimaryPractice(user, patient) &&
    user?.practiceId?._id === patient?.primaryPractice?._id
  ) {
    isShowAddButton = true;
  }

  useDataFetchEvents({eventSourceId: 'contactAttempts'}, () => {
    getContactAttemptsList();
    getTaskStatus();
  });

  const getContactAttemptsList = async () => {
    const response = await HttpAuthService.get(routeLink.getContactAttempt, {
      params: {
        filter: {
          taskId: taskId,
        },
        fields: {
          mode: {
            name: 1,
          },
          person: {
            name: 1,
          },
          updatedBy: {
            name: 1,
          },
          createdAt: {
            name: 1,
          },
          updatedAt: {
            name: 1,
          },
          date: 1,
          contactStatus: 1,
        },
        sort: {
          createdAt: 1,
        },
      },
    });
    setContactAttemptList(response.data);
  };

  const getTaskStatus = async () => {
    const response = await HttpAuthService.get(routeLink.getTCMTask, {
      params: {
        filter: {
          taskId: taskId,
          encounterId: encounter._id,
        },
        only: true,
      },
    });
    setTaskDetail(response.data);
  };

  useEffect(() => {
    getContactAttemptsList();
    getTaskStatus();
  }, []);

  return (
    <View style={{flex: 1, overflow: 'hidden', maxHeight: '335px'}}>
      <TableHeader
        title={'Contact Attempts'}
        actions={[
          isShowAddButton ? (
            <Button
              key="ContactAttempts"
              text="Contact Attempts"
              onPress={() => {
                navigate(
                  `/patient/details/encounter/${encounter._id}/${taskId}/add-contact-attempts`,
                  {
                    state: {
                      patient,
                      encounter,
                      titleName: patient.FullName,
                    },
                  },
                );
              }}
              icon={images.addUser}
              iconPosition="right"
              buttonType={buttonType.linkButtonWithBorder}
            />
          ) : null,
        ]}
      />
      <BasicTable
        variant={'outline'}
        data={contactAttemptList}
        columns={[
          {
            header: 'Date',
            field: 'date',
            type: 'date',
            formatOptions: {
              format: Config.dateFormat,
            },
          },
          {
            header: 'Method',
            field: 'mode.name',
            type: 'text',
          },
          {
            render: RenderAttemptCount,
          },
          {
            render: RenderStatus,
          },
        ]}
        {...props}
      />
    </View>
  );
};
