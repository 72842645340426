import React from 'react';
import {matchRoutes, useLocation, useNavigate} from 'react-router-dom';
import images from '../assets/images';
import {Image} from '@unthinkable/react-core-components';
import {children} from '../routes/AppNavigator';
import {CBreadcrumb} from '@coreui/react';
import {BreadCrumbContainer, BreadCrumbText} from './AppBreadcrumb.style';

const AppBreadcrumb = () => {
  const {pathname: currentLocation, state} = useLocation();

  //add hooks for navigation
  const navigate = useNavigate();
  const getRouteName = (pathname, routes) => {
    return matchRoutes(routes, pathname)?.pop()?.route?.name;
  };

  const getBreadcrumbs = location => {
    const breadcrumbs = [];
    location.split('/').reduce((prev, curr) => {
      const currentPathname = `${prev}/${curr}`;
      const routeName = getRouteName(currentPathname, children);
      if (routeName) {
        breadcrumbs.push({
          pathname: currentPathname,
          name:
            state?.titleName && location == currentPathname
              ? routeName + ` - ${state.titleName}`
              : routeName,
        });
      }
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="m-0 ms-2">
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <BreadCrumbContainer
            key={index}
            onClick={() => {
              if (index !== breadcrumbs.length - 1)
                navigate(-(breadcrumbs.length - 1 - index));
            }}>
            <Image source={images.NavRight} />
            <BreadCrumbText
              style={
                index !== breadcrumbs.length - 1 ? {cursor: 'pointer'} : null
              }>
              {breadcrumb.name}
            </BreadCrumbText>
          </BreadCrumbContainer>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(AppBreadcrumb);
