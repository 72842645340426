import {employerType} from '../constant/employerType';
import {authorization, guestAuthorization} from '../constant/authorization';
import {USER_TYPE} from '../constant/userType';
const checkAuthorization = (user, entity, action) => {
  if (
    user?.userType?.name == USER_TYPE.FACILITY_ADMIN ||
    user?.userType?.name == USER_TYPE.FACILITY_USER
  ) {
    if (!user?.facilityId?.isSubscribed) {
      return guestAuthorization[user?.userType?.name]?.[entity]?.includes(
        action,
      );
    }
  }
  return authorization[user?.userType?.name]?.[entity]?.includes(action);
};
export const checkPrimaryPractice = (user, patient) => {
  if (
    user?.userType?.name === 'Admin' ||
    user?.employerType?.name !== employerType?.practice
  )
    return true;
  return user?.practiceId?._id === patient?.primaryPractice?._id;
};

export default checkAuthorization;
